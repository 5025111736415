<template>
  <Popover class="relative">
    <PopoverButton class="inline-flex items-center py-3 pl-5 pr-3 text-lg font-medium text-gray-100 duration-150 rounded-full outline-none hover:bg-gray-100/10">
      <span>{{ title }}</span>
      <IconShare class="w-6 h-6 ml-4 text-gray-100 transition duration-150 ease-in-out"
                 aria-hidden="true"
      />
    </PopoverButton>

    <Transition enter-active-class="transition duration-200 ease-out"
                enter-from-class="translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel class="absolute right-0 z-10 px-0 mt-3 transform">
        <div class="w-full p-6 bg-white shadow-lg rounded-3xl ring-1 ring-black ring-opacity-5 md:w-max">
          <div class="flex justify-between mb-4">
            <h3 class="text-xl font-medium text-gray-600">
              {{ title }}
            </h3>
            <PopoverButton class="group">
              <span class="sr-only">Close</span><IconCloseLine class="w-8 h-8 duration-100 group-hover:opacity-50" />
            </PopoverButton>
          </div>
          <div class="flex flex-wrap gap-x-8 gap-y-4 md:gap-y-0">
            <div class="flex gap-x-3">
              <NuxtLink v-for="(link, i) in shareLinks"
                        :key="i"
                        class="relative flex items-center justify-center mx-1.5 text-gray-400 border border-gray-400 rounded-full w-14 h-14 lg:mx-0 flex-shrink-0 box-border group bg-white"
                        target="_blank"
                        :to="link.url"
                        @click="handleClick(link.label)"
              >
                <span class="sr-only">{{ link.label }}</span>
                <component :is="`Icon${link.icon}`"
                           class="h-5"
                />
                <span class="absolute transition-opacity duration-200 rounded-full opacity-0 inset-[-1px] bg-pink-blue mix-blend-lighten group-hover:opacity-100" />
              </NuxtLink>
            </div>

            <BlogButtonCopy :url="url"
                            type="light"
                            @click="handleClick('Copy link share')"
            />
          </div>
        </div>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useRuntimeConfig } from '#app';
import { useRoute } from 'vue-router';
import { useNuxtApp } from '#imports';

import useShareLinks from '../composables/share-links';

interface SharePopover {
  title: string;
}
defineProps<SharePopover>();

const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const { $event } = useNuxtApp();

const url = runtimeConfig.public.domain + route.path;
const shareLinks = useShareLinks({ url });

function handleClick(linkLabel: string) {
  $event('Sharing', { props: { [linkLabel]: route.path } });
}
</script>
